'use strict';

angular.module('managerApp').config(function ($routeProvider) {
    $routeProvider.when('/login', {
        templateUrl: 'app/account/login/login.html',
        controller: 'LoginController'
    }).when('/logout', {
        name: 'logout',
        referrer: '/',
        template: '',
        controller: function ($scope, $location, $mdDialog, $mdToast, users, Auth) {
            function doLogout() {
                Auth.logout();
                $mdToast.hide();
                $mdDialog.cancel();
                setTimeout(() => {
                    $location.path('/');
                    $scope.$apply();
                });
            }
            users.logout().then(() => {
                doLogout();
            }).catch((err) => {                
                if (err === 'Unauthorized' || err === 'Bad Request') {
                    doLogout();
                }
            });
        }
    });
}).run(function ($rootScope) {
    $rootScope.$on('$routeChangeStart', function (event, next, current) {
        if (next.name === 'logout' && current && current.originalPath
                && !current.authenticate) {
            next.referrer = current.originalPath;
        }
    });
});
